import { Fragment, useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { JoinPageContext } from "../../context/join_page_context";
import AutoRenewFailed from "./AutoRenewFailed";
import Payment from "./Payment";
import Golfer from "./Golfer";
import Guardian from "./Guardian";
import Address from "./Address";
import Membership from "./Membership";
import ClubJoinPage from "./ClubJoinPage";
import VerifyGolfer from "./VerifyGolfer";
import VerifyCode from "./VerifyCode";
import GolferDetails from "./GolferDetails";
import Receipt from "./Receipt";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { update } from "../../features/club/clubSlice";
import JoinPageService from "../../services/api/join_page";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import Pending from "./Pending";
import CardUpdated from "./CardUpdated";

function ClubHome() {
  const { url, club_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { JoinPageData } = useContext(JoinPageContext);
  const existing_club_data = useSelector((state) => state.club.club_data) || null;
  const [club, set_Club] = useState({});
  const root = document.documentElement;
  const parse = require('html-react-parser');
  const isBlocked = sessionStorage.getItem('auto_renew_failed') === 'true' || sessionStorage.getItem('update_card_details') === 'true'
  let location = useLocation();
  const addfullWidth = (htmlCode) => {
    if (htmlCode)
      if (htmlCode.props)
        if (htmlCode.props.style)
          htmlCode.props.style.width = '100%';

    return htmlCode;
  }
  root.style.setProperty(
    "--preferred-color",
    JoinPageData.golf_association_details &&
      JoinPageData.golf_association_details.color
      ? `#${JoinPageData.golf_association_details.color}`
      : "black"
  );

  useEffect(() => {
    let club_join_page = location.pathname.split('/').length === 4

    if (String(existing_club_data.id) !== club_id  || club_join_page){
      JoinPageService.getClubJoinPageInformation(url, club_id)
        .then((res) => {
          const data = { ...res.join_page };
        
          if ((data.online_renewal_enabled === false && data.online_renewal_enabled === false) || data.club_membership_fees.length === 0) {
            navigate('../inactive_join_page');
          }
          const club_data = {
            id: data.club_details.id,
            name: data.club_details.name,
            phone: data.club_details.phone,
            email: data.club_details.email,
            primary_contact: data.club_details.primary_contact,
            website: data.club_details.website,
            city: data.club_details.city,
            country: data.club_details.country_name,
            street_1: data.club_details.street_1,
            street_2: data.club_details.street_2,
            state: data.club_details.state,
            zip_code: data.club_details.zip_code,
            page_description: data.page_description,
            header_text: data.header_text,
            customization_club_details: data.customization_club_details,
            online_join_enabled: data.online_join_enabled,
            online_renewal_enabled: data.online_renewal_enabled,
            auto_renewal_enabled: data.auto_renewal_enabled,
            approval_needed: data.approval_needed,
            has_offers: data.has_offers
          };
          set_Club(club_data);
          dispatch(update({...club_data}));
          if (data.online_join_enabled === true && data.online_renewal_enabled !== true)
            navigate(`../club/${club_data.id}/golfer`, {
              state: {
                previous_page: '',
              }
            });
          else if (data.online_join_enabled !== true && data.online_renewal_enabled === true)
            navigate(`../club/${club_data.id}/verify_golfer`, {
              state: {
                previous_page: '',
              }
            });
        })
        .catch((err) => {
          const err_msg = err?.data?.errors?.join_page[0];
          if (err_msg === 'Incomplete Join Page')
            navigate('../inactive_join_page');
          else 
            navigate("/");
        });
      }
    else 
      set_Club(existing_club_data); 
  }, [url, club_id, navigate, JoinPageData, dispatch]);
  
  const parsePhone = (phone) => {
    if (phone !== null && phone !== undefined && phone !== ""){
      const val = phone.toString();
      if (val.length === 10)
        phone = `(${phone.slice(0,3)}) ${phone.slice(3,6)}-${phone.slice(6,10)}`;
    }
    
    return phone;
  }

  const restrictedAccessMessage = (
    <div style={{textAlign: 'center', fontSize: '26px', paddingBottom: "60px"}}>
      <strong>
        Your access to this section is restricted.
        <br />
        Please retry using the link you previously received in your email.
      </strong>
    </div>
  );

  return (
    <Fragment>
      <div className="logo-image-div text-align-center">
        <img
          className="logo-image"
          src={JoinPageData.header_image}
          onError={(e) => {e.target.style.display = 'none'}}
        />
        <span className="club-name-header">{club.name}</span>
      </div>
      <div className="first-page-container grey-bottom-line">
        <div className='header-text'>
          <div>
            {club.header_text && addfullWidth(parse(club.header_text))}
          </div>
        </div>

        {club.header_text && <div style={{height: '20px'}}></div>}

        <div className='header-text'>
          {club.page_description && addfullWidth(parse(club.page_description))}
        </div>
      </div>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}>
        <div className="flex-grow ">
          <Routes>
            <Route path="/golfer/:ghinNumber/payment" element={<AutoRenewFailed />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/card_updated" element={<CardUpdated />} />
            <Route path="/receipt" element={<Receipt/>}/>
            <Route path="/pending" element={<Pending />}/>
            <Route path="/golfer" element={isBlocked ? restrictedAccessMessage : <Golfer/>}/>
            <Route path="/guardian" element={isBlocked ? restrictedAccessMessage : <Guardian/>}/>
            <Route path="/address" element={isBlocked ? restrictedAccessMessage : <Address billing={false}/>}/>
            <Route path="/billing_address" element={<Address billing={true}/>}/>
            <Route path="/membership" element={isBlocked ? restrictedAccessMessage : <Membership/>}/>
            <Route path="/" element={<ClubJoinPage/>}/>
            <Route path="/verify_golfer" element={isBlocked ? restrictedAccessMessage : <VerifyGolfer />} />
            <Route path="/code_verification" element={isBlocked ? restrictedAccessMessage : <VerifyCode />} />
            <Route path="/golfer_details" element={isBlocked ? restrictedAccessMessage : <GolferDetails />} />
            <Route path="/invalid_payment_gateway" element={
              <p style={{textAlign: 'center', fontSize: '26px', paddingBottom: "60px"}}>
                <strong>
                  The Payment Gatway is not set properly.
                  <br></br>
                  Please contact the association for assistance.
                </strong>
              </p>
            }/>
            <Route path="/unavailable" element={
              <p style={{textAlign: 'center', fontSize: '26px', paddingBottom: "60px", paddingTop: "40px"}}>
                <strong>The page is no longer available.</strong>
              </p>
            }/>
          </Routes>
        </div>
      </GoogleReCaptchaProvider>

      <footer>
        <div className="logo-image-div grey-bottom-line">
          <hr className="gray-bar"/>
          
          <div className="row small_padding">
            <div className="col is-4-of-8 flex-end">
              <div className="info-box">
                <p className="text-align-center bold">
                  {JoinPageData.golf_association_details &&
                    JoinPageData.golf_association_details.name}
                </p>

                <p className="text-align-center">
                  {JoinPageData.golf_association_details &&
                    JoinPageData.golf_association_details.street_1}
                </p>
                <p className="text-align-center">
                  {JoinPageData.golf_association_details &&
                    JoinPageData.golf_association_details.street_2}
                </p>
                <p className="text-align-center">
                  {JoinPageData.golf_association_details &&
                    `${JoinPageData.golf_association_details.city}, ${JoinPageData.golf_association_details.state} ${JoinPageData.golf_association_details.zip_code}`}
                </p>
                <p className="text-align-center">
                  {JoinPageData.golf_association_details && (
                      <a href={`mailto:${JoinPageData.golf_association_details.email}`}>
                        {JoinPageData.golf_association_details.email}
                      </a>
                  )}
                </p>

                <p className="text-align-center">
                  {JoinPageData.golf_association_details &&
                      parsePhone(JoinPageData.golf_association_details.phone)}

                  {JoinPageData.golf_association_details &&
                      JoinPageData.golf_association_details.link && (
                          <span> |{' '}
                            <a href={`http://${JoinPageData.golf_association_details.link}`} target="_blank" rel="noopener noreferrer">
                          {JoinPageData.golf_association_details.link}
                        </a>
                      </span>
                      )}
                </p>
              </div>
            </div>

            <div className="small_padding col is-4-of-8 flex-start">
              <div className="info-box">
                <p className="text-align-center bold">
                  {club.name}
                </p>

                <p className="text-align-center">
                  {club.street_1}
                </p>
                {club.street_2 && <p className="text-align-center">
                  {club.street_2}
                </p>}
                <p className="text-align-center">
                  {`${club.city}, ${club.state} ${club.zip_code}`}
                </p>
                <p className="text-align-center">
                  {club.email && (
                      <a href={`mailto:${club.email}`}>
                        {club.email}
                      </a>
                  )}
                </p>

                <p className="text-align-center">
                  {club.phone && parsePhone(club.phone)}
                  {club.website && club.phone && (
                      <span> |{' '}
                        <a href={`http://${club.website}`} target="_blank" rel="noopener noreferrer">
                          {club.website}
                        </a>
                      </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

export default ClubHome;
